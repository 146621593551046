import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '*',
    name: 'Home',
    component: Home
},
{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/About.vue')
},
{
    path: "/contact",
    name: "Contaxt",
    component: () =>
        import( /* webpackChunkName: "contact" */ '../views/Contact.vue')
},
{
    path: "/priceCalculator",
    name: "PriceCalculator",
    component: () =>
        import( /* webpackChunkName: "priceCalculator" */ '../views/PriceCalculator.vue')
},
{
    path: "/reservationSuccess",
    name: "ReservationSuccess",
    component: () =>
        import( /* webpackChunkName: "reservationSuccess" */ '../views/ReservationSuccess.vue')
}

]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router